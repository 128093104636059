<template>
  <div class="hero-case-study">
    <LetsChat></LetsChat>
    <div class="grid-container">
      <div class="hero__content logo-hape">
        <img src="@/assets/casestudy/hape-logo.svg" alt="Hape">
        <span class="sep"></span>
        <p>Hape believes that to play is in the DNA of every child, our job is just to awaken it and guide it gently. Our toys are created to inspire play, learn, and explore the world we live in.
          Through responsible business practices we aspire to leave the world in a better condition than we received it.</p>
      </div>
    </div>

    <div class="laptop-img hape-laptop">
      <div class="grid-container">
        <img src="@/assets/casestudy/hape-main-img.png" alt="Hape">
      </div>
    </div>

    <div class="container-about hape-about">
      <div class="grid-container">
        <img src="@/assets/casestudy/hape-diamond.svg" alt="Hape">
        <h3>About the project</h3>
        <p>Hape Toys Inc. is the largest producer of wooden toys! We’ve been pleased to work with their local branch in Romania since 2014, offering local support and development.<br/>
          They have also been so happy with our collaboration that we’ve been selected by the holding to develop their marketing platform for Hape and three of their subsidiaries – Käthe Kruse,
          Beleduc & Senger.</p>
      </div>
    </div>

    <div class="container-typography">
      <div class="grid-container">
        <div class="container-services__content">
          <h3>What we do</h3>
          <div class="wwd-left">
            <h4>Managed services</h4>
            <img src="@/assets/casestudy/web-app-r.svg" width="60px" height="60px">
            <h4>Support</h4>
            <img src="@/assets/casestudy/maintenance-icon-r.svg" width="60px" height="60px">
            <h4>SEO</h4>
            <img src="@/assets/casestudy/consultancy-icon-r.svg" width="60px" height="60px">
          </div>
          <span class="sep vertical"></span>
          <div class="wwd-right">
            <img src="@/assets/casestudy/managed-services-r.svg" width="60px" height="60px">
            <h4>Custom Web App's</h4>
            <img src="@/assets/casestudy/support-icon-r.svg" width="60px" height="60px">
            <h4>Maintenance</h4>
            <img src="@/assets/casestudy/seo-icon-r.svg" width="60px" height="60px">
            <h4>Consultancy</h4>
          </div>

          <h3>Typography</h3>

          <div class="font-typo">
            <img class="doublefont" src="@/assets/casestudy/opensans-font.svg" alt="Hape Main Font">
            <h3><span style="color:#e1221a">Co</span><span style="color:#c4c3c3">lo</span><span style="color:#333">rs</span></h3>

            <div class="colors">
              <div class="fcolor hape-p">Primary</div>
              <div class="scolor hape-s">Secondary</div>
            </div>

            <span class="sep vertical color-tag careful-sep"></span>

            <div class="color-codes cc-careful">
              <div class="ccodes p-careful">
                <p class="primary-hap">#e1221a</p>
                <p class="secondary-hap">#333333</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container-nav-projects">
      <div class="grid-container">
        <div class="container-nav-projects__content">
          <h3>Related projects</h3>

          <div class="container-nav-projects-left">
            <router-link :to="{ name: 'Wayspa'}" class="left">
              <img src="@/assets/casestudy/btn-wayspa.jpg" alt="Wayspa">
            </router-link>
          </div>

          <div class="container-nav-projects-right">
            <router-link :to="{ name: 'Summerwood'}" class="right">
              <img src="@/assets/casestudy/btn-sw.jpg" alt="Summerwood">
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <ContactUs color="#e1221a"></ContactUs>
    <Footer></Footer>
  </div>
</template>

<script>
import ContactUs from "../../components/ContactUs";
import Footer from "../../components/Home/Footer";
import LetsChat from "../../components/LetsChat";

export default {
  name: "Hape",
  components: {LetsChat, Footer, ContactUs}
}
</script>

<style lang="scss">
.logo-hape img {
  max-width: 320px;
}

.hape-laptop {
  background-color: #e1221a;
}

.hape-about {
  background-color: #222;
}

.container-about h3 {
  color: #e1221a;
}

.container-about p {
  color: #fafafa;
}

.hape-p {
  background-color: #e1221a;
}

.primary-hap {
  color: #e1221a;
}

.hape-s {
  background-color: #333333;
}

.secondary-hap {
  color: #333333;
}

.hape-typo h3 {
  text-align: center;
}

.container-nav-projects__content h3 {
  display: block;
  background: rgb(58, 58, 58);
  background: linear-gradient(125deg, rgba(58, 58, 58, 1) 50%, #e1221a 60%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>